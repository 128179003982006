.wrapper {
  max-width: 960px;
  padding: 15px;
  margin: 50px auto 150px;
}

.video {
  margin-top: 25px;
  width: 100%;
}

.videoHolder {
  width: 100%;
}

.video:first-child {
  margin-top: 0;
}
